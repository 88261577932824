<template>
  <v-app>
    <v-container fluid>
        <body class="application application-offset">
            <div class="container-fluid container-application">
                <mainMenu /> <!-- ==== 左選單 ==== -->

                <div class="main-content position-relative">
                    <mainNav /> <!-- ==== Top選單 ==== -->

                    <div class="page-content index">
        
                        <!-- Page title -->
                        <div class="page-title">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-md-6 mb-3 mb-md-0" style="z-index:5">
                                    <h5 class="h3 font-weight-400 mb-0 text-white">嗨，{{userName}}!</h5>
                                    <span class="text-sm text-white opacity-8">祝你有個美好的一天!</span>
                                </div>
                            </div>
                        </div>

                        <div class="card" style="z-index: 2;">
                          <div class="card-header actions-toolbar border-0">
                            <div class="row justify-content-between align-items-center">
                                <div class="col" style="padding-top: 0px;padding-bottom: 0px;">
                                    <h2 class="d-inline-block mb-0 card-title">事業單位人員維護</h2>
                                </div>
                            </div>
                          </div>
                          <br/>
                            <!-- ==========主要內容區========== -->
                            <v-form ref="form" class="px-12" v-model="isValid">
                              <v-row>
                                  <v-col sm="6">
                                    <v-card
                                      elevation="5"
                                      :height="maxHeight"
                                      outlined
                                      @click.stop="showCaptureDialog(memberData.orgmId)"
                                    >
                                      <v-layout row wrap  align-center justify-center fill-height>
                                        <v-card-text class='grey--text text-center caption px-6' >
                                          <v-icon x-large v-if="captureData.fileId === ''">mdi-camera</v-icon>
                                          <v-img
                                            contain
                                            :max-height="maxHeight-50"
                                            class="white--text align-end"
                                            :src="pictureData">
                                          </v-img>
                                        </v-card-text>
                                      </v-layout>
                                    </v-card>
                                  </v-col>
                                  <v-col sm="6">
                                    <v-card
                                      elevation="5"
                                      :height="maxHeight"
                                      outlined
                                    >
                                      <v-layout row wrap  align-center justify-center fill-height>
                                        <v-card-text class='grey--text text-center caption px-6' >
                                          <v-chip
                                            class="ma-2"
                                            large
                                            v-if = "qrcodeData === null"
                                            >
                                            QR code
                                          </v-chip>
                                          <v-img
                                            contain
                                            :max-height="maxHeight-50"
                                            class="white--text align-end"
                                            :src="qrcodeData">
                                          </v-img>
                                           
                                        </v-card-text>
                                      </v-layout>
                                    </v-card>
                                  </v-col>
                              </v-row>        
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                              </v-row>   
                              <v-row>
                                <v-col>
                                  <v-select
                                    :items="orgList"
                                    v-model="memberData.orgId"
                                    label="事業單位名稱"
                                    @input="getSubUnitList"
                                    :clearable  = "this.loginAuth.role === 'S'"
                                    :rules="[rules.required]" required
                                    :readonly = "this.loginAuth.role !== 'S'"
                                  ></v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field label="編號" readonly outlined v-model="memberData.orgmCode"></v-text-field>
                                </v-col>
                                <v-col>
                                  <v-text-field label="姓名" outlined v-model="memberData.name" :rules="[rules.required]" required></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field label="職稱" outlined v-model="memberData.jobTitle"></v-text-field>
                                </v-col>
                                <v-col>
                                  <v-text-field label="工作性質" outlined v-model="memberData.jobDescription"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-text-field label="手機" outlined v-model="memberData.mobilePhone"></v-text-field>
                                </v-col>
                                <v-col>
                                  <v-text-field label="email" outlined v-model="memberData.email"></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-subheader>
                                    學經歷(受訓紀錄等)
                                  </v-subheader>
                                </v-col>
                                <v-col sm="1">
                                  <v-btn
                                    class="ma-2"
                                    text
                                    icon
                                    color="#37906C"
                                    @click="addResumeData"
                                  >
                                     <v-icon large class="mt-1">mdi-plus-box</v-icon>
                                  </v-btn>
                                </v-col>
                              </v-row>        
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                              </v-row>
                              <v-row>
                                <v-col>
                                  <v-data-table
                                  :headers="headers"
                                  :items="resumeList"
                                  hide-default-footer
                                  hide-default-header
                                  disable-sort>
                                    <template v-slot:[`item.name`]={item}>
                                      <v-text-field v-model="item.name" label="學經歷" outlined hide-details dense></v-text-field>
                                    </template>
                                    <template v-slot:[`item.act`]={item}>
                                      <v-btn class="mr-4 white--text" color="#4D4D4D" v-if="!item.editStatus" @click="deleteUnitData(item)">
                                        <v-icon>mdi-delete-forever</v-icon>
                                      </v-btn>
                                    </template>
                                  </v-data-table>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                                <br/>
                              </v-row>
                              <v-row>
                                <v-col>
                                  
                                  <v-select
                                    v-model="memberData.memberPropertyList"
                                    :items="memberPropertyList"
                                    item-text="text"
                                    item-value="value"
                                    attach
                                    chips
                                    label="人員屬性"
                                    multiple
                                  >
                                    <template #selection="{ item }">
                                      <v-chip class="ma-2" color="primary">{{item.text}}</v-chip>
                                    </template>
                                  </v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                                <br/>
                              </v-row>
                              <v-row>
                                <v-subheader>
                                  子單位
                                </v-subheader>
                              </v-row>        
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                              </v-row>                      
                              <v-row>
                                <v-col>
                                    <ul>
                                        <li v-for="(item, index) in subUnitList" :key="index" style="width: 33%;float:left;list-style-type:none;">
                                            <v-checkbox
                                                v-model="subUnitRelationshipList"
                                                :label="item.suName"
                                                :value="item"
                                                :readonly = "!isCreater"
                                            ></v-checkbox>
                                        </li>
                                    </ul>
                                </v-col>
                              </v-row>
                              <div v-if="checkPlusFunc('oscInterfaceEnable')">
                                <v-row>
                                  <v-divider class="my-0"></v-divider>
                                  <br/>
                                </v-row>
                                <v-row>
                                  <v-subheader>
                                    臺灣職安卡資料
                                  </v-subheader>
                                </v-row>
                                <v-row>
                                  <v-divider class="my-0"></v-divider>
                                </v-row>
                                <v-row>
                                  <v-col v-if="memberData.oscInterfaceStatus!=='F'">
                                      <v-tabs color="deep-purple accent-4" >
                                        <v-tab @click="setOscInterfaceOption('')">職安卡資料</v-tab>
                                        <v-tab @click="setOscInterfaceOption('D')">清除介接資料</v-tab>
                                        <v-tab @click="setOscInterfaceOption('R')">重新介接</v-tab>
                                      </v-tabs>
                                      <v-card>
                                        <v-card-text style="font-size:18px;color: black;">
                                          人員姓名：{{memberData.oscInterfaceDataEntity.name}} <br/>
                                          臺灣職安卡卡號：{{memberData.oscInterfaceDataEntity.account}} <br/>
                                          血型：{{memberData.oscInterfaceDataEntity.bloodType}} <br/>
                                          性別：{{memberData.oscInterfaceDataEntity.gender}} <br/>
                                          參訓日期：{{memberData.oscInterfaceDataEntity.firstTrainingDate}} <br/>
                                        </v-card-text>
                                      </v-card>
                                  </v-col>
                                  <v-col v-if="memberData.oscInterfaceStatus==='F'">
                                    <v-tabs color="deep-purple accent-4" >
                                      <v-tab>介接職安卡資料</v-tab>
                                    </v-tabs>
                                    <v-card>
                                      <v-text-field v-model="memberData.oscAccount" label="職安卡卡號" outlined hide-details class="mt-3" ></v-text-field>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </div>
                              <v-row>
                                <v-divider class="my-0"></v-divider>
                                <br/>
                              </v-row>
                            
                              <v-row justify="center">
                                    <v-btn class="mr-4 white--text" min-width="100" color="#37906C" large @click="submitData" :disabled="saveStatus || !isValid">
                                      儲存
                                    </v-btn>
                                    <v-btn class="mr-4 white--text" color="#4D4D4D" large @click="toCancel">
                                      回上一頁
                                    </v-btn>
                              </v-row>
                            </v-form>
                            <!-- ==========主要內容區========== -->
                            <br/>
                        </div>
                    </div>

                    <mainFooter /><!-- Footer -->
                    
                </div>
            </div>
        </body>
        <loadingMask :showLoading="isLoading" style="z-index:9999"></loadingMask>
        <captureDialog :showCapture="isCaptureDialog" :captureData="captureData"  @captureEvent="setCaptureData($event)" />
    </v-container>
  </v-app>
</template>

<script>
import mainMenu from '@/components/mainMenu.vue'
import mainNav from '@/components/mainNav.vue'
import mainFooter from '@/components/mainFooter.vue'
import commonMixin from '@/utils/commonMixin'
import loadingMask from '@/components/public/loadingMask.vue'
import captureDialog from '@/components/public/captureDialog.vue'
import {getOrgnizationMemberData, saveOrgnizationMember, addOrgnizationMember, downloadFile, 
getOrgnizationNameSelectOption, getOrgnizationData, getSysParamListByCode} from "@/api/hyirecoApi"

export default {
  name: 'orgnizationMemberEdit',
  mixins: [commonMixin],
  created(){
    this.authValidate();
    this.auth = {
      headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.getTokenWithSession(),
      }
    };

    this.picAuth = {
      headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.getTokenWithSession(),
      },
      responseType: 'blob',
    };

    //console.log("this.loginAuth = " + JSON.stringify(this.loginAuth));
    this.getOrgList();
    this.getMemberProperty();
    //console.log("checkPlusFunc = " + this.checkPlusFunc("oscInterfaceEnable"));
  },
  destroyed() {
    clearInterval(this.interval);
  },
  computed: {
    headers() {
      return [
          {
            text: '名稱',
            align: 'start',
            value: 'name',
            class: "subtitle-2 font-weight-black",
            width: "500",
          },
          { text: '動作', value: 'act', class: "subtitle-2 font-weight-black", align: 'start',width:"100"},
      ]
    },
  },
  mounted(){
    //console.log("this.loginAuth = " + JSON.stringify(this.loginAuth));
    this.userName = this.loginAuth.memberName;
    if (this.loginAuth.role !== "S") {
      this.memberData.orgId = this.loginAuth.orgnization.orgId;
      this.orgnizationName = this.loginAuth.orgnization.name;
      if (this.loginAuth.role === "O") {
        this.subUnitList = this.loginAuth.orgnization.subUnitList;
      } else {
        this.subUnitList = this.loginAuth.subUnitRelationshipList.map(function(item) {
          return item.subUnit;
        });
      }
    } 
    
    if (this.$route.params.orgmId !== "init") { 
      this.getOrgData();
    } 
  },
  methods: {
    setOscInterfaceOption: function(value) {
      if (value == "D") {
        this.$swal("選取 清除介接資料 需儲存後生效");
      } else if (value == "R") {
        this.$swal("選取 重新介接 需儲存後更新");
      }
      this.memberData.oscInterfaceOption = value;
    },
    getMemberProperty: function(){
      var vm = this;
      
      getSysParamListByCode("people",vm.auth).then(function(response) {
        if ("200" == response.status) { 
          var responseData = response.data;
          //console.log("responseData = " + JSON.stringify(responseData));
          if (responseData.statusCode === "1") {
            responseData.sysParam.childParamList.forEach(function(para){
              var tmpArr =  para.childParamList.map(function(child){
                              var item = new Object();
                              item.text = para.name + "-" + child.name;
                              item.value = child.code;
                              return item;
                            });
              //console.log("tmpArr = " + JSON.stringify(tmpArr));
              vm.memberPropertyList = vm.memberPropertyList.concat(tmpArr);
            });
          }
        }
      });
    },
    getOrgList: function(){
      var vm = this;

      getOrgnizationNameSelectOption(vm.auth).then(function(response) {
        if ("200" == response.status) { 
          var responseData = response.data;
          vm.orgList = responseData.orgnizationNameSelectOption.map(function(item) {
            var obj = new Object();
            obj.text = item.optionText;
            obj.value = item.optionValue;
            return obj;
          });
        }
      });      
    },
    getOrgData: function(){
      this.isLoading = true;
      var vm = this;
      getOrgnizationMemberData(vm.$route.params.orgmId, vm.auth).then(function(response) {
          if ("200" == response.status) { 
              var responseData = response.data;
              console.log("getOrgnizationMemberData responseData = " + JSON.stringify(responseData));

              vm.memberData = responseData.orgnizationMember;
              vm.orgnizationName = vm.memberData.orgnization.name;

              //學經歷
              vm.resumeList = vm.memberData.resumeList.map(function(item){
                var objUnit = new Object();
                objUnit.name = item;
                return objUnit;
              }); 

              if (vm.loginAuth.role === "J") {
                //如果是場域管理人員的話只能修改自己建立資料的子單位
                vm.isCreater = vm.loginAuth.userID == vm.memberData.createName;
              } else {
                //如果非場域管理人員，則讀出所有子單位
                vm.subUnitList = vm.memberData.orgnization.subUnitList
              }

              vm.subUnitRelationshipList = vm.memberData.subUnitRelationshipList.map(function(item) {
                  return item.subUnit;
              });
              //console.log("getOrgnizationMemberData subUnitRelationshipList = " + JSON.stringify(vm.subUnitRelationshipList));
              //console.log("getOrgnizationMemberData resumeList = " + JSON.stringify(vm.resumeList));

              //照片
              vm.getPictureData(vm.memberData.photo);
              vm.captureData.fileId = vm.memberData.photo;
              vm.getqrcode(vm.memberData.qrcode);

              if (typeof vm.memberData.oscInterfaceDataEntity === "undefined") {
                vm.memberData.oscInterfaceDataEntity = {};
                vm.memberData.oscInterfaceDataEntity.name = "";
                vm.memberData.oscInterfaceDataEntity.account = "";
                vm.memberData.oscInterfaceDataEntity.bloodType = "";
                vm.memberData.oscInterfaceDataEntity.gender = "";
                vm.memberData.oscInterfaceDataEntity.firstTrainingDate = "";  
              }

              //console.log("memberData.oscInterfaceStatus = " + vm.memberData.oscInterfaceStatus);
          }
      });
      this.isLoading = false;
    },
    submitData: function() {
      var vm = this;
      vm.isLoading = true;
      vm.saveStatus = true;

      if (vm.memberData.photo === "null" || vm.memberData.photo === "" || typeof vm.memberData.photo === "undefined") {
        vm.$swal("照片為必填欄位");
        vm.isLoading = false;
        vm.saveStatus = false;
        return;
      }

      var apiObj = saveOrgnizationMember;
      if (this.$route.params.orgmId === "init") { 
        apiObj = addOrgnizationMember;
        vm.memberData.oscInterfaceStatus = 'N';
      }

      // if (vm.memberData.oscInterfaceStatus==='F') {
      //   vm.memberData.oscInterfaceOption = "R";
      // }

      vm.memberData.resumeList = vm.resumeList.map(function(item){ return item.name; });
      vm.memberData.subUnitRelationshipList = vm.subUnitRelationshipList;
      var dataObj = new Object();
      dataObj.orgnizationMember = vm.memberData;
    
      console.log("dataObj = " + JSON.stringify(dataObj));
      apiObj(dataObj, vm.auth).then(function(response) {
          if ("200" == response.status) { 
            var responseData = response.data;
            vm.$swal(responseData.message);
            if (responseData.statusCode === "1") {
              vm.toCancel();
            }
            console.log("responseData = " + JSON.stringify(response));
            vm.saveStatus = false;
            vm.isLoading = false;
          }
      });
    },
    toCancel: function () {
      this.$router.push({ path: "/orgnizationMember/orgnizationMemberList" });
    },
    addResumeData: function(){
      var vm = this;
      console.log(vm.resumeList.length);
      var objUnit = new Object();
      objUnit.name = "";
      vm.resumeList.push(objUnit);
    },
    deleteUnitData: function(obj) {
      this.resumeList.splice(this.resumeList.indexOf(obj),1);
    },
    showCaptureDialog: function(id) {
      this.captureData.randomId = id;
      this.captureData.fileId = "";
      this.isCaptureDialog = true;
      //console.log(this.isCaptureDialog);
    },
    setCaptureData: function(value) {
      this.isCaptureDialog = false;
      if (value !== null) {
        this.getPictureData(value.fileId);
        this.memberData.photo = value.fileId;
      }
    },
    getPictureData: function(picId){
      var vm = this;
      downloadFile(picId, vm.picAuth).then(function(response) {
        if ("200" == response.status) {
          var blob=new Blob([response.data]);
          vm.blobToDataURI(blob,  function (data) {
              vm.pictureData = data;
              //console.log("vm.pictureData = " + vm.pictureData);
          });
        }
      });
    },
    getqrcode: function(picId){
      var vm = this;
      downloadFile(picId, vm.picAuth).then(function(response) {
        if ("200" == response.status) {
          var blob=new Blob([response.data]);
          vm.blobToDataURI(blob,  function (data) {
            vm.qrcodeData = data;
          });
        }
      });
    },
    getSubUnitList: function() {
      var vm = this;
      vm.subUnitRelationshipList = [];
      vm.subUnitList = [];
      if (this.loginAuth.role === "S") {
        if (vm.memberData.orgId !== null) {
          getOrgnizationData(vm.memberData.orgId, vm.auth).then(function(response) {
            if ("200" == response.status) { 
              var responseData = response.data;
              vm.subUnitList = responseData.orgnization.subUnitList;
            }
          });
        }
      } else {
        vm.subUnitList = this.loginAuth.subUnitRelationshipList.map(function(item) {
          var obj = new Object();
          obj.text = item.suName;
          obj.value = item.suName;
          return obj;
        });
      }

    },
  },
  watch: {
    // captureData: function(value) {
    //   this.getPictureData(value.fileId);
    // },
  },
  data: () => ({
    rules: {
      required: value => !!value || '必填欄位',
      requiredForLabor: value => !!value || '若沒有請填 無',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || '不符規格的 e-mail 信箱'
      },
    },
    userName: "",
    orgnizationName: "",
    maxHeight: 250,
    memberData: {
      oscInterfaceDataEntity:{
        name: "",
        account: "",
        bloodType: "",
        gender: "",
        firstTrainingDate: "",
      },
    },
    isLoading: false,
    auth: {},
    picAuth: {},
    saveStatus: false,
    isValid: true,
    resumeList:[],
    subUnitList:[],
    subUnitRelationshipList:[],
    orgList:[],
    isCaptureDialog: false,
    qrcodeData: null,
    captureData: {
        memberId:"",
        fileId: "",
    },
    memberPropertyList: [],
    pictureData: null,
    isCreater: true,
  }),
  components: {
    mainMenu,
    mainNav,
    mainFooter,
    loadingMask,
    captureDialog,
  },
}
</script>

<style scoped>
.v-input__slot > .v-label
{
    margin-bottom: 0px;
}
.v-subheader {
    color: rgb(34, 34, 34) !important;
    font-size: large;
}

</style>